import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { capitalize } from 'lodash'
import Layout from '../components/Layout'
import Collection from '../components/Collection'

const TagPage = ({ data, pageContext }) => {
  const { title, frontmatterValue } = pageContext
  const { edges: recipes } = data.allMarkdownRemark

  return (
    <Layout>
      <Collection
        title={`${title}: ${capitalize(frontmatterValue)}`}
        items={recipes}
      />
    </Layout>
  )
}

TagPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default TagPage

export const pageQuery = graphql`
  query TagPage($frontmatterValue: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$frontmatterValue] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            ...previewFrontmatter
          }
        }
      }
    }
  }
`
